/* Footer Background */
.footerBG {
  background-color: white;
  padding-top: 100px;
  padding-bottom: 50px;
  font-family: Arial, sans-serif;
}

/* Contact Us Section */
.contactUs {
  max-width: 1100px;
  margin: 0 auto;
  padding: 40px;
  margin-top: 20px;
  background-color: #02124c; /* Dark blue container */
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Subtle shadow */
  color: white;
}

/* Title Section inside the container */
.titleContainer {
  text-align: center;
  margin-bottom: 20px;
}

.titleContainer h1 {
  font-size: 32px;
  font-weight: bold;
  margin: 0;
}

.headingLine {
  width: 80px;
  height: 3px;
  background-color:white;
  margin: 10px auto;
}

/* Map and Details Container */
.mapAndDetails {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 20px;
}

/* Map Section */
.mapSection {
  flex: 1;
}

.map {
  width: 100%;
  height: 300px;
  border-radius: 10px;
  border: none;
}

/* Vertical Divider */
.verticalLine {
  width: 2px;
  background-color: white;
  height: auto;
  margin: 0 20px;
}

/* Contact Info Section */
.contactInfo {
  flex: 1;
}

.details h2 {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
  color:white; /* Highlight for headings */
}

.details p {
  font-size: 14px;
  margin: 5px 0;
  color: white;
}

.social h2 {
  margin-top: 15px;
}

.linkedImg {
  width: 25px;
  height: 25px;
  margin-top: 10px;
  transition: transform 0.3s ease;
}

.linkedImg:hover {
  transform: scale(1.2);
}

/* Watermark Section */
.watermark {
  text-align: center;
  font-size: 12px;
  margin-top: 20px;
  color: #ccc;
}