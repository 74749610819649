/* Main header styling */
.header {
  position: fixed; /* Keep header fixed at the top */
  top: 0px;
  left: 0px;
  width: 100%; /* Ensure full-width */
  height: 80px; /* Standard header height */
  background-color: white; /* White background for clarity */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add shadow for better visibility */
  z-index: 1000; /* Ensure it stays above all other content */
  display: flex;
  align-items: center;
  justify-content: space-between; /* Space between logo and menu */
  padding: 0 30px; /* Add horizontal padding */
}

/* Logo styling */
.logo {
  display: flex;
  align-items: flex-start; /* Align logo to the top */
}

.logoImg {
  width: 50px; /* Adjust logo size */
  height: auto;
}

/* Navigation menu styling */
.menu {
  display: flex;
  align-items: center;
}

.menulist .nav-link {
  color: #333333; /* Default text color */
  font-size: 1rem; /* Standard font size */
  font-weight: 500; /* Semi-bold font */
  text-transform: uppercase; /* Uppercase for menu items */
  text-decoration: none; /* Remove underline */
  padding: 10px 20px; /* Spacing between links */
  transition: color 0.3s ease, border-bottom 0.3s ease; /* Smooth hover effect */
}

.menulist .nav-link:hover {
  color: #d32f2f; /* Red color on hover */
}

.menulist .nav-link.active {
  color: #d32f2f; /* Highlight the active menu option */
  border-bottom: 2px solid #d32f2f; /* Add a red underline for the active menu */
}

/* Responsive Design */
@media (max-width: 768px) {
  .header {
    height: auto;
    flex-direction: column; /* Stack logo and menu on smaller screens */
    padding: 10px 20px;
  }

  .menu {
    justify-content: center; /* Center-align menu on smaller screens */
    margin-top: 10px;
  }

  .menulist .nav-link {
    font-size: 0.9rem; /* Slightly reduce font size */
    padding: 5px 10px; /* Reduce padding for smaller screens */
  }
}