/* Scoped CSS for Home Page - Updated for Wix-Like Design */

.homePage .homeBg {
    background: url('/public/homepage.avif') no-repeat center center/cover;
    min-height: 80vh; /* Adjusted height for better background focus */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    font-family: 'Poppins', sans-serif;
    padding: 0;
    margin: 0;
    position: relative;
  }
  
  .homePage .in {
    position: relative;
    z-index: 2;
    width: 100%;
    max-width: 1200px;
    margin: auto;
    text-align: center;
  }
  
/* Styled floating-cont to mimic Wix container */
.homePage .floating-cont {
    background: rgba(242, 242, 242, 0.9); /* Light grey with transparency */
    backdrop-filter: blur(10px); /* Add blur effect */
    border-radius: 5px; /* Rounded edges */
    padding: 40px 30px; /* Space inside the box */
    max-width: 700px; /* Width for the container */
    margin: -50px auto; /* Slight overlap with background */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    text-align: center; /* Center-align text inside */
    position: absolute; /* Allows absolute positioning for button */
    top: 70%; /* Move container vertically to center */
    left: 30%; /* Move container horizontally to center */ 
    transform: translate(25%,35%); /* Adjust to offset the container vertically */
}
  
  .homePage .title {
    font-size: 2.4rem; /* Larger title */
    font-weight: 700; /* Bold */
    color: #002b5b; /* Dark navy blue */
    margin-bottom: 20px; /* Space below the title */
    text-transform: uppercase; /* Uppercase styling */
    line-height: 1.3; /* Adjust line height for readability */
  }
  
  .homePage .title-body {
    font-size: 1.1rem;
    line-height: 1.8;
    color: #555555; /* Subtle grey for readability */
    margin-bottom: 40px; /* Space between text and button */
    text-align: center;
  }
  
  .homePage .find-more {
    position: absolute;
    bottom: 20px; /* Distance from the bottom */
    right: 20px; /* Distance from the right */
  }
  
  .homePage .btnFind {
    background-color: #d32f2f; /* Red button for CTA */
    border: none;
    color: #ffffff;
    font-weight: bold;
    padding: 12px 30px;
    border-radius: 25px; /* Rounded edges for button */
    font-size: 1rem;
    text-transform: uppercase;
    text-decoration: none;
    transition: all 0.3s ease-in-out;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2); /* Subtle shadow for button */
  }
  
  .homePage .btnFind:hover {
    background-color: #b71c1c; /* Darker red on hover */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
    transform: scale(1.05); /* Slight scaling effect */
  }
  
  @media (max-width: 768px) {
    .homePage .floating-cont {
      padding: 30px 20px;
      margin: -40px auto 20px;
    }
  
    .homePage .title {
      font-size: 2rem;
    }
  
    .homePage .title-body {
      font-size: 1rem;
    }
  
    .homePage .btnFind {
      font-size: 0.9rem;
      padding: 10px 20px;
    }
  }
  
  @media (max-width: 480px) {
    .homePage .floating-cont {
      padding: 20px;
      margin: -30px auto 10px;
    }
  
    .homePage .title {
      font-size: 1.8rem;
    }
  
    .homePage .title-body {
      font-size: 0.9rem;
    }
  
    .homePage .btnFind {
      font-size: 0.8rem;
      padding: 8px 15px;
    }
  }