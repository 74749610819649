body {
    margin: 0;
    font-family: Arial, sans-serif;
    background-color: #f8f8f8;
    line-height: 1.6;
  }
  
  /* Blue Panel Section */
  .bluePanel {
    background-color: #02124c; /* Dark navy blue */
    padding: 80px 50px;
    text-align: center;
    border: 5px solid #01103f;
  }
  
  .heading {
    color: white;
    font-size: 48px;
    font-weight: bold;
    font-family: 'Poppins', sans-serif;
    margin: 0;
  }

  /* Content Section */
  .content {
    width: 100%;
    max-width: 900px;
    margin: 50px auto;
    padding: 0 20px;
  }
  
  .intro {
    font-size: 16px;
    margin-bottom: 20px;
    text-align: left;
  }
  
  .section {
    margin-bottom: 30px;
  }

  .highlight {
    color: rgb(185, 17, 17);
    font-style: italic;
  }
  
  .section h2 {
    font-size: 22px;
    color: #02124c;
    margin-bottom: 10px;
  }
  
  .section h3 {
    font-size: 18px;
    color: #444;
    margin-bottom: 8px;
  }
  
  .section p {
    font-size: 16px;
    margin-bottom: 15px;
  }
  
  .section ul {
    list-style-type: disc;
    margin-left: 20px;
  }
  
  .section ul li {
    font-size: 16px;
    margin-bottom: 10px;
  }
  /* Back Button Styling */
.backButton {
    position: fixed; /* Sticks the button to a fixed position */
    bottom: 20px; /* 20px from the bottom */
    right: 20px; /* 20px from the right */
    background-color: #02124c; /* Dark navy blue */
    color: #fff; /* White text */
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    padding: 10px 20px;
    border-radius: 8px;
    text-decoration: none;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, background-color 0.3s ease;
  }
  
  .backButton:hover {
    background-color: #003399; /* Brighter navy blue on hover */
    transform: scale(1.1); /* Slight zoom on hover */
  }