/* Background Section */
.clientBg {
    width: 100%;
    height: 50vh;
    margin-top: 70px;
    background-image: url("/public/clients.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
  }
  
  /* Container for the content */
  .containerClient {
  position: absolute; /* Ensure it is positioned relative to the parent */
  top: 80%; /* Vertically center */
  left: 32%; /* Horizontally center */
  transform: translate(-50px,-50px); /* Adjust position to perfectly center */
  background-color: rgba(255, 255, 255, 0.9); /* Slight transparency */
  width: 80%; /* Adjust width for responsiveness */
  max-width: 700px; /* Limit maximum width */
  padding: 30px; /* Add padding for content */
  border-radius: 12px; /* Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  text-align: center; /* Center-align text */
}
  
  /* Heading Text */
  .cont {
    font-family: 'Arial', sans-serif;
    color: #06248d;
    font-size: 36px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  /* Logos Section */
  .logoPartner {
    display: flex;
    flex-wrap: wrap;
    justify-content: center; /* Center-align logos */
    padding: 20px 0;
    gap: 20px; /* Spacing between logos */
  }
  
  /* Individual Logo Styling */
  .logoPartner .logosImg {
    width: 80px;
    height: 80px;
    object-fit: contain; /* Ensure logos are contained without cropping */
    margin: 10px;
    transition: transform 0.3s ease, box-shadow 0.3s ease; /* Add hover effect */
  }
  
  /* Hover Effect on Logos */
  .logoPartner .logosImg:hover {
    transform: scale(1.1); /* Slight zoom on hover */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Shadow effect */
  }