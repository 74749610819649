/* Main container styling */
.findmore {
  font-family: 'Poppins', sans-serif;
  color: #333333;
  line-height: 1.8;
  margin: 0;
  padding: 0;
  background-color: #f9f9f9;
}

/* Header section styling */
.findmore .top {
  position: relative; /* Needed for absolute positioning of the floating container */
  height: 55vh; /* Set height for the header section */
  display: flex;
  justify-content: center;
  align-items: center;
  background: url('/public/about.png') no-repeat center center/cover; /* Background image */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Floating container styling */
.findmore .whyChoose {
  position: absolute; /* Floating effect relative to the `.top` section */
  bottom: -40px; /* Slight overlap below the image */
  left: 50%; /* Center horizontally */
  transform: translateX(-50%); /* Adjust position to truly center */
  z-index: 2; /* Ensure it stays above the image and other elements */
  background: rgba(255, 255, 255); /* White with slight transparency */
  padding: 20px 40px; /* Padding inside the box */
  border-radius: 8px; /* Rounded corners for a modern look */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15); /* Subtle shadow for depth */
  text-align: center; /* Center-align the text */
} /* Limit width of the floating box */

.findmore .whyChoose {
  width: 90%; /* Dynamic width for better responsiveness */
  max-width: 700px; /* Ensure it doesn't get too wide */
}

/* Title inside the floating container */
.findmore .whyChoose .title {
  font-size: 2.5rem; /* Title font size */
  font-weight: bold;
  color: #002b5b; /* Professional navy blue color */
  text-transform: uppercase; /* Uppercase styling */
  margin: 0; /* Remove default margin */
}

/* Content body styling (write-up section) */
.findmore .cont-body {
  max-width: 900px;
  margin: 80px auto 0; /* Add spacing above and center it */
  padding: 0; /* Remove all padding */
  color: #333333; /* Standard text color */
  line-height: 1.8; /* Improved line spacing */
  text-align: left; /* Left-align text for readability */
}

/* Paragraphs inside the content body */
.findmore .cont-body div {
  margin-bottom: 20px; /* Add spacing between paragraphs */
  font-size: 1.1rem; /* Standard font size */
  color: #333333; /* Slightly darker text color */
}

/* Emphasized sections */
.findmore .cont-body .red {
  color: #d32f2f; /* Highlight important text in red */
  font-weight: bold;
  font-size: 1.2rem; /* Slightly larger text for emphasis */
  margin-bottom: 10px;
}

/* Bullet point styling */
.findmore .cont-body .bullet {
  margin-left: 20px; /* Indent bullet points */
  margin-bottom: 10px;
  position: relative;
  padding-left: 25px; /* Space between bullet and text */
  font-size: 1.1rem; /* Standard bullet font size */
  color: #333333;
}

/* Bullet point symbol */
.findmore .cont-body .bullet::before {
  content: "•";
  color: #d32f2f; /* Red bullet symbol for contrast */
  font-size: 1.4rem;
  position: absolute;
  left: 0;
  top: 0;
}

/* Responsive styling */
@media (max-width: 768px) {
  .findmore .whyChoose {
    padding: 15px 20px;
    bottom: -30px; /* Adjust overlap for smaller screens */
  }

  .findmore .whyChoose .title {
    font-size: 2.2rem;
  }

  .findmore .cont-body {
    margin-top: 60px; /* Reduce spacing for smaller screens */
  }

  .findmore .cont-body div {
    font-size: 1rem; /* Adjust font size for better readability */
  }

  .findmore .cont-body .bullet::before {
    font-size: 1.2rem;
  }
}

@media (max-width: 480px) {
  .findmore .whyChoose {
    padding: 10px 15px;
    bottom: -20px; /* Further adjust overlap for very small screens */
  }

  .findmore .whyChoose .title {
    font-size: 2rem;
  }

  .findmore .cont-body {
    margin-top: 50px; /* Adjust spacing */
  }

  .findmore .cont-body div {
    font-size: 0.9rem; /* Smaller font size */
  }

  .findmore .cont-body .bullet::before {
    font-size: 1rem;
  }
}