/* Services Section Styling */
.serviceBg {
    width: 100%;
    background-color: #f9f9f9; /* Light gray background for the entire section */
    padding-bottom: 60px; /* Space at the bottom */
  }
  
  /* Blue Panel */
  .serviceBg .bluePanel {
    background-color: #02124c; /* Dark navy blue background */
    padding: 80px 50px; /* Increased padding for a larger panel */
    text-align: center;
    margin-bottom: 60px; /* Space below the blue panel */
  }
  
  /* Section Heading */
  .heading {
    color: white; /* White text inside blue panel */
    font-size: 48px;
    font-weight: bold;
    font-family: 'Poppins', sans-serif; /* Clean, modern font */
    margin: 0;
  }
  
  /* Cards Container */
  .modules {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* 3 cards per row */
    gap: 30px; /* Spacing between cards */
    margin: 0 auto;
    padding: 0 40px; /* Add padding for responsiveness */
    max-width: 1200px; /* Limit the width for better alignment */
  }
  
  /* Individual Card Styling */
  .modules .container {
    background: linear-gradient(145deg, #ffffff, #f1f1f1); /* Subtle gradient for a premium look */
    border-radius: 12px; /* Rounded corners */
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.1), -4px -4px 10px rgba(255, 255, 255, 0.9); /* Neumorphic shadow */
    padding: 20px 25px;
    text-align: left; /* Align text to the left */
    transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth hover effects */
    position: relative;
    overflow: hidden; /* Hide elements that go outside the container */
  }
  
  /* Hover Effect for Cards */
  .modules .container:hover {
    transform: translateY(-10px); /* Slight lift */
    box-shadow: 6px 6px 15px rgba(0, 0, 0, 0.2), -6px -6px 15px rgba(255, 255, 255, 0.9); /* Enhanced shadow */
    background: linear-gradient(145deg, #e6e6e6, #ffffff); /* Change background gradient on hover */
  }
  
  /* Decorative Overlay Effect */
  .modules .container::before {
    content: '';
    position: absolute;
    top: -50%;
    left: -50%;
    width: 200%;
    height: 200%;
    background: radial-gradient(circle, rgba(255, 255, 255, 0.3), transparent);
    z-index: 0;
    opacity: 0;
    transition: opacity 0.5s ease;
  }
  
  .modules .container:hover::before {
    opacity: 1;
  }
  
  /* Title Styling */
  .title {
    color: #02124c; /* Dark navy blue text for titles */
    font-size: 20px; /* Slightly larger font size */
    font-weight: 600; /* Semi-bold for emphasis */
    margin-bottom: 15px;
    line-height: 1.4; /* Improved spacing for multi-line titles */
    font-family: 'Poppins', sans-serif;
    z-index: 1; /* Ensure text stays above overlay */
    position: relative;
  }
  
  /* Description Styling */
  .title-body {
    font-size: 14px; /* Slightly smaller font size for description */
    color: #555555; /* Neutral gray text for readability */
    line-height: 1.6; /* Proper line spacing */
    margin-bottom: 20px; /* Space below the description */
    font-family: 'Arial', sans-serif;
    z-index: 1; /* Ensure text stays above overlay */
    position: relative;
  }
  
  /* Learn More Button */
  .learnMore {
    margin-top: auto; /* Push Learn More to the bottom */
  }
  
  .btnLearn {
    color: #d32f2f; /* Red text for Learn More */
    font-size: 14px;
    text-decoration: underline;
    background: none;
    border: none;
    cursor: pointer;
    transition: color 0.3s ease, transform 0.3s ease; /* Smooth hover effect */
    z-index: 1; /* Ensure button stays above overlay */
    position: relative;
  }
  
  .btnLearn:hover {
    color: #ff0000; /* Brighter red on hover */
    transform: scale(1.1); /* Slight zoom on hover */
  }
  
  /* Responsive Design */
  @media (max-width: 1024px) {
    .modules {
      grid-template-columns: repeat(2, 1fr); /* 2 cards per row on medium screens */
      gap: 20px; /* Slightly reduced spacing */
    }
  }
  
  @media (max-width: 768px) {
    .modules {
      grid-template-columns: repeat(1, 1fr); /* 1 card per row on small screens */
      padding: 0 20px;
    }
  }